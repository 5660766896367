<template>
  <div class="container-fluid">
    <h1 class="text-center">Music</h1>

    <div v-if="musicCategories.length > 0">
      <div v-for="category in musicCategories"
          :key="category.music_category_id">
        <!-- Category Title -->
        <h3> {{ category.music_category_title }} </h3>

        <!-- Album Carousel -->
        <carousel :categoryId="category.music_category_id"></carousel>
      </div>

      <!-- Load More -->
      <div class="text-center"
          v-if="lastPage && currentPage !== lastPage">
        <b-button class="btn btn-h3-small btn-h3-blue text-center mt-3 mb-3"
                  type="button"
                  @click="loadMoreCategories">
          Load More
        </b-button>
      </div>
    </div>

    <!-- No Content -->
    <div v-else>
      <no-content v-if="!isLoading">
        Music is still not available.
      </no-content>
    </div>
  </div>
</template>

<script>
  const Carousel = () =>
  import('@/components/user/category/AlbumCarousel.vue');
  const NoContent = () => import('@/components/user/common/NoContent');

  export default {
    name : 'MusicCategories',
    data() {
      return {
        musicCategories : [],
        perPage         : 10,
        currentPage     : 0,
        lastPage        : 0,
        isLoading       : false,
      }
    },
    components : {
      Carousel,
      NoContent,
    },
    methods : {

      /**
       * Get All Music Categories
       * @param currentPage Pagination's current page
       */
      getMusicCategories(currentPage = this.currentPage) {
        this.isLoading = true;
        this.$http.get('api/music/categories', {
          params : {
            page    : currentPage + 1,
            perPage : this.perPage,
          },
        }).then(response => {
          this.currentPage = response.data.result.currentPage;
          this.lastPage = response.data.result.lastPage;

          if (this.currentPage > 1) {
            const arr1 = this.musicCategories;
            const arr2 = response.data.result.data;

            this.musicCategories = arr1.concat(arr2);
          } else
            this.musicCategories = response.data.result.data;

        }).catch(() => {
          this.musicCategories = [];
        }).finally(() => {
          this.isLoading = false;
        });
      },

      /**
       * Load More Music Categories
       */
      loadMoreCategories() {
        this.getMusicCategories();
      },
    },
    mounted() {
      this.getMusicCategories();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/category/music-categories";
</style>